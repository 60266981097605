import React from "react";
import links from "pages/app/links";

import RegularMenu from "./components/regularMenu";

import { useMediaQuery } from "react-responsive";
import MobileMenu from "./components/mobileMenu";

const designAndArchBase = links.designAndArch.link;
const computationBase = links.computation.link;
const storiesBase = links.stories.link;
const artBase = links.art.link;
// const { aFrameHouse, drawingCrags } = links.stories.children;

const menuItems = [
  {
    name: "Design & Architecture",
    children: links.designAndArch.children,
    base: designAndArchBase
  },
  {
    name: "Computation",
    children: links.computation.children,
    base: computationBase
  },
  {
    name: "Stories",
    children: links.stories.children,
    base: storiesBase
  },
  {
    name: "Art",
    children: links.art.children,
    base: artBase
  },
  {
    name: "Bio",
    link: "/bio"
  }
];

const MainMenu: React.FC = () => {
  const isSmallScreen = useMediaQuery({ query: "(max-width: 1224px)" });

  if (isSmallScreen) {
    return <MobileMenu menuItems={menuItems} />;
  } else {
    return <RegularMenu menuItems={menuItems} />;
  }
};

export default MainMenu;
