import React from "react";
import { Block, RegPage, RegPageBody } from "components/uiElements/projectElements";

import style from "./style.module.scss";

const AtoriesRouterIndex: React.FC = () => {
  return (
    <RegPage>
      <RegPageBody>
        <Block>
          <div className={style.header}>Stories</div>
          <div className={style.something}>One Day this will be something</div>
        </Block>
      </RegPageBody>
    </RegPage>
  );
};
export default AtoriesRouterIndex;
