import React, { Suspense, lazy } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import Spinner from "components/spinner";

import DesignAndArchRouter from "pages/designAndArch/designAndArchRouter";
import StoriesRouter from "pages/stories/storiesRouter";
import ArtRouter from "pages/art/ArtRouter";
import ComputationRouter from "pages/computation/computationRouter";
import MainMenu from "components/uiElements/menu";
import Footer from "templates/footer/footer";

import styles from "./mainRouter.module.scss";
import ScrollToTop from "./components/scrollToTop";

const Homepage = lazy(() => import("pages/home"));
const BioPage = lazy(() => import("pages/bio"));
const NotFoundpage = lazy(() => import("pages/notFound"));

// TODO - make more generic

const Router: React.FC = () => {
  return (
    <Suspense fallback={<Spinner />}>
      <BrowserRouter>
        <ScrollToTop />
        <MainMenu />
        <div className={styles.mainContainer}>
          <Switch>
            <Route exact path="/" component={Homepage} />
            <Route path="/bio" component={BioPage} />
            <Route path="/art" component={ArtRouter} />
            <Route path="/design-and-arch" component={DesignAndArchRouter} />
            <Route path="/computation" component={ComputationRouter} />
            <Route path="/stories" component={StoriesRouter} />
            <Route path="*" component={NotFoundpage} />
          </Switch>
        </div>
        <Footer />
      </BrowserRouter>
    </Suspense>
  );
};

export default Router;
