import neighborhood_perspective_1 from "pages/designAndArch/theUnknownLand/assets/images/neighborhood_perspective_1.jpg";
import neighborhood_perspective_2 from "pages/designAndArch/theUnknownLand/assets/images/neighborhood_perspective_2.jpg";
import _8 from "pages/designAndArch/theUnknownLand/assets/images/sketches/_8.png";
import _9 from "pages/designAndArch/theUnknownLand/assets/images/sketches/_9.png";
import _2 from "pages/designAndArch/theUnknownLand/assets/images/sketches/_2.png";
import _3 from "pages/designAndArch/theUnknownLand/assets/images/sketches/_3.png";
import _1 from "pages/designAndArch/theUnknownLand/assets/images/sketches/_1.jpg";
import _4 from "pages/designAndArch/theUnknownLand/assets/images/sketches/_4.png";
import _5 from "pages/designAndArch/theUnknownLand/assets/images/sketches/_5.png";
import _10 from "pages/designAndArch/theUnknownLand/assets/images/sketches/_10.png";
import _7 from "pages/designAndArch/theUnknownLand/assets/images/sketches/_7.png";
import _6 from "pages/designAndArch/theUnknownLand/assets/images/sketches/_6.png";
import _11 from "pages/designAndArch/theUnknownLand/assets/images/sketches/_11.png";
import facade_radiation_2 from "pages/designAndArch/theUnknownLand/assets/images/facadeRadiation/facade_radiation_2.jpg";
import facade_radiation_3 from "pages/designAndArch/theUnknownLand/assets/images/facadeRadiation/facade_radiation_3.jpg";
import facade_radiation_1 from "pages/designAndArch/theUnknownLand/assets/images/facadeRadiation/facade_radiation_1.jpg";
import facade_radiation_4 from "pages/designAndArch/theUnknownLand/assets/images/facadeRadiation/facade_radiation_4.jpg";
import facade_radiation_5 from "pages/designAndArch/theUnknownLand/assets/images/facadeRadiation/facade_radiation_5.jpg";
import facade_radiation_7 from "pages/designAndArch/theUnknownLand/assets/images/facadeRadiation/facade_radiation_7.jpg";
import facade_radiation_6 from "pages/designAndArch/theUnknownLand/assets/images/facadeRadiation/facade_radiation_6.jpg";
import facade_radiation_8 from "pages/designAndArch/theUnknownLand/assets/images/facadeRadiation/facade_radiation_8.jpg";
import movement from "pages/designAndArch/theUnknownLand/assets/images/movement_patterns/movement.jpg";
import movement_2 from "pages/designAndArch/theUnknownLand/assets/images/movement_patterns/movement_2.jpg";
import whole_area_perspective_section from "pages/designAndArch/theUnknownLand/assets/images/whole_area_perspective_section.jpg";
import roof_plan from "pages/designAndArch/theUnknownLand/assets/images/roof_plan.jpg";
import first_floor_plan from "pages/designAndArch/theUnknownLand/assets/images/first_floor_plan.jpg";
import rendering_1 from "pages/designAndArch/theUnknownLand/assets/images/rendering_1.jpg";
import rendering_2 from "pages/designAndArch/theUnknownLand/assets/images/rendering_2.jpg";

const assets = {
  neighborhood_perspective_1: {
    assetType: "image",
    name: "neighborhood_perspective_1",
    src: neighborhood_perspective_1,
    width: 3000,
    height: 1333,
    order: 1
  },
  neighborhood_perspective_2: {
    assetType: "image",
    name: "neighborhood_perspective_2",
    src: neighborhood_perspective_2,
    width: 6000,
    height: 1500,
    order: 2
  },
  sketches: {
    assetType: "imageSequence",
    name: "sketches",
    width: 3572,
    height: 4032,
    assets: {
      _8: {
        assetType: "image",
        name: "_8",
        src: _8,
        width: 1333,
        height: 918,
        order: 0
      },
      _9: {
        assetType: "image",
        name: "_9",
        src: _9,
        width: 1333,
        height: 941,
        order: 1
      },
      _2: {
        assetType: "image",
        name: "_2",
        src: _2,
        width: 3572,
        height: 2470,
        order: 2
      },
      _3: {
        assetType: "image",
        name: "_3",
        src: _3,
        width: 2492,
        height: 3342,
        order: 3
      },
      _1: {
        assetType: "image",
        name: "_1",
        src: _1,
        width: 3024,
        height: 4032,
        order: 4
      },
      _4: {
        assetType: "image",
        name: "_4",
        src: _4,
        width: 2000,
        height: 2062,
        order: 5
      },
      _5: {
        assetType: "image",
        name: "_5",
        src: _5,
        width: 2000,
        height: 2418,
        order: 6
      },
      _10: {
        assetType: "image",
        name: "_10",
        src: _10,
        width: 1333,
        height: 1292,
        order: 7
      },
      _7: {
        assetType: "image",
        name: "_7",
        src: _7,
        width: 1333,
        height: 819,
        order: 8
      },
      _6: {
        assetType: "image",
        name: "_6",
        src: _6,
        width: 2000,
        height: 2102,
        order: 9
      },
      _11: {
        assetType: "image",
        name: "_11",
        src: _11,
        width: 1333,
        height: 1833,
        order: 10
      }
    },
    sequenceType: "row",
    order: 3
  },
  facadeRadiation: {
    assetType: "imageSequence",
    name: "facadeRadiation",
    width: 3000,
    height: 1800,
    assets: {
      facade_radiation_2: {
        assetType: "image",
        name: "facade_radiation_2",
        src: facade_radiation_2,
        width: 3000,
        height: 1800,
        order: 0
      },
      facade_radiation_3: {
        assetType: "image",
        name: "facade_radiation_3",
        src: facade_radiation_3,
        width: 3000,
        height: 1800,
        order: 1
      },
      facade_radiation_1: {
        assetType: "image",
        name: "facade_radiation_1",
        src: facade_radiation_1,
        width: 3000,
        height: 1800,
        order: 2
      },
      facade_radiation_4: {
        assetType: "image",
        name: "facade_radiation_4",
        src: facade_radiation_4,
        width: 3000,
        height: 1800,
        order: 3
      },
      facade_radiation_5: {
        assetType: "image",
        name: "facade_radiation_5",
        src: facade_radiation_5,
        width: 3000,
        height: 1800,
        order: 4
      },
      facade_radiation_7: {
        assetType: "image",
        name: "facade_radiation_7",
        src: facade_radiation_7,
        width: 3000,
        height: 1800,
        order: 5
      },
      facade_radiation_6: {
        assetType: "image",
        name: "facade_radiation_6",
        src: facade_radiation_6,
        width: 3000,
        height: 1800,
        order: 6
      },
      facade_radiation_8: {
        assetType: "image",
        name: "facade_radiation_8",
        src: facade_radiation_8,
        width: 3000,
        height: 1800,
        order: 7
      }
    },
    sequenceType: "row",
    order: 4
  },
  movement_patterns: {
    assetType: "imageSequence",
    name: "movement_patterns",
    width: 2000,
    height: 1200,
    assets: {
      movement: {
        assetType: "image",
        name: "movement",
        src: movement,
        width: 2000,
        height: 1200,
        order: 0
      },
      movement_2: {
        assetType: "image",
        name: "movement_2",
        src: movement_2,
        width: 2000,
        height: 1200,
        order: 1
      }
    },
    sequenceType: "row",
    order: 5
  },
  whole_area_perspective_section: {
    assetType: "image",
    name: "whole_area_perspective_section",
    src: whole_area_perspective_section,
    width: 1963,
    height: 1137,
    order: 6
  },
  roof_plan: {
    assetType: "image",
    name: "roof_plan",
    src: roof_plan,
    width: 5500,
    height: 3321,
    order: 7
  },
  first_floor_plan: {
    assetType: "image",
    name: "first_floor_plan",
    src: first_floor_plan,
    width: 5500,
    height: 3321,
    order: 8
  },
  rendering_1: {
    assetType: "image",
    name: "rendering_1",
    src: rendering_1,
    width: 2000,
    height: 1003,
    order: 9
  },
  rendering_2: {
    assetType: "image",
    name: "rendering_2",
    src: rendering_2,
    width: 2000,
    height: 1146,
    order: 10
  }
};

export default assets;
