import assets from "./assets";

import links from "pages/app/links";

// export type ElementDataType = {
//   asset: Asset;
//   metadata: {
//     name: string;
//     title?: string;
//     subTitle?: string;
//     link?: string;
//   };
//   link: string;
//   style: React.CSSProperties;
// };

// export type ElementsDataType = Record<string, ElementDataType>;

export const ElementData = {
  designAndArch: {
    e18Towers: {
      asset: assets.designAndArch.e18TowersAssets.apartment_combinations,
      metadata: links.designAndArch.children.e18Towers,
      link: `${links.designAndArch.link}/${links.designAndArch.children.e18Towers.link}`,
      style: { minWidth: "380px", width: "32%", marginLeft: "55%", marginTop: "5%" }
    },
    futurome: {
      asset: assets.designAndArch.futuromeAssets.birds_eye_edited,
      metadata: links.designAndArch.children.futurome,
      link: `${links.designAndArch.link}/${links.designAndArch.children.futurome.link}`,
      style: { minWidth: "380px", width: "30%", marginLeft: "17%", marginTop: "37%" }
    },
    theUnknownLand: {
      asset: assets.designAndArch.theUnknownLandAssets.rendering_2,
      metadata: links.designAndArch.children.theUnknownLand,
      link: `${links.designAndArch.link}/${links.designAndArch.children.theUnknownLand.link}`,
      style: { minWidth: "380px", width: "25%", marginLeft: "48%", marginTop: "60%" }
    },
    acreMuseum: {
      asset: assets.designAndArch.acreMuseumAssets.final_rendering,
      metadata: links.designAndArch.children.acreMuseum,
      link: `${links.designAndArch.link}/${links.designAndArch.children.acreMuseum.link}`,
      style: {
        minWidth: "380px",
        maxWidth: "540px",
        width: "35%",
        marginLeft: "30%",
        marginTop: "95%"
      }
    },
    apartmentExplorations: {
      asset: assets.designAndArch.apartmentExplorationsAssets.type_2_rendering,
      metadata: links.designAndArch.children.apartmentExplorations,
      link: `${links.designAndArch.link}/${links.designAndArch.children.apartmentExplorations.link}`,
      style: {
        minWidth: "380px",
        maxWidth: "540px",
        width: "36%",
        marginLeft: "67%",
        marginTop: "78%"
      }
    },
    visualizingClimate: {
      asset: assets.designAndArch.visualizingClimateAssets.sunpath_oneHour_allYear_lines,
      metadata: links.designAndArch.children.visualizingClimate,
      link: `${links.designAndArch.link}/${links.designAndArch.children.visualizingClimate.link}`,
      style: { minWidth: "200px", width: "20%", marginLeft: "60%", marginTop: "167%" }
    }
  },
  computation: {
    elytraPavilion: {
      asset: assets.computation.elytraPavilionAssets.microClimate,
      metadata: links.computation.children.elytraPavilion,
      link: `${links.computation.link}/${links.computation.children.elytraPavilion.link}`,
      style: { minWidth: "400px", width: "35%", marginLeft: "48%", marginTop: "138%" }
    },
    cdp: {
      asset: assets.computation.cdpAssets.sketch_cdp_1,
      metadata: links.computation.children.cdp,
      link: `${links.computation.link}/${links.computation.children.cdp.link}`,
      style: { minWidth: "380px", width: "45%", marginLeft: "10%", marginTop: "170%" }
    },

    solarEnvelopes: {
      asset: assets.computation.solarEnvelopesAssets.solarRights,
      metadata: links.computation.children.solarEnvelopes,
      link: `${links.computation.link}/${links.computation.children.solarEnvelopes.link}`,
      style: { minWidth: "280px", width: "40%", marginLeft: "50%", marginTop: "190%" }
    },
    lux: {
      asset: assets.computation.luxAssets.interiorLighting,
      metadata: links.computation.children.lux,
      link: `${links.computation.link}/${links.computation.children.lux.link}`,
      style: { minWidth: "380px", width: "25%", marginLeft: "45%", marginTop: "255%" }
    }
  }
};
