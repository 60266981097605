import solid from "pages/computation/parametricSolarEnvelopes/images/solid.jpg";
import solarCollection from "pages/computation/parametricSolarEnvelopes/images/solarCollection.jpg";
import surface from "pages/computation/parametricSolarEnvelopes/images/surface.jpg";
import sunHoursPers from "pages/computation/parametricSolarEnvelopes/images/sunHoursPers.jpg";
import solarRights from "pages/computation/parametricSolarEnvelopes/images/solarRights.jpg";
import sunHoursTop from "pages/computation/parametricSolarEnvelopes/images/sunHoursTop.jpg";
import pointCloud from "pages/computation/parametricSolarEnvelopes/images/pointCloud.jpg";
import solarEnvelope from "pages/computation/parametricSolarEnvelopes/images/solarEnvelope.jpg";


const assets = {
  "solid": {
    "assetType": "image",
    "name": "solid",
    "src": solid,
    "width": 910,
    "height": 391,
    "order": 0
  },
  "solarCollection": {
    "assetType": "image",
    "name": "solarCollection",
    "src": solarCollection,
    "width": 759,
    "height": 561,
    "order": 1
  },
  "surface": {
    "assetType": "image",
    "name": "surface",
    "src": surface,
    "width": 910,
    "height": 373,
    "order": 2
  },
  "sunHoursPers": {
    "assetType": "image",
    "name": "sunHoursPers",
    "src": sunHoursPers,
    "width": 1012,
    "height": 750,
    "order": 3
  },
  "solarRights": {
    "assetType": "image",
    "name": "solarRights",
    "src": solarRights,
    "width": 698,
    "height": 403,
    "order": 4
  },
  "sunHoursTop": {
    "assetType": "image",
    "name": "sunHoursTop",
    "src": sunHoursTop,
    "width": 783,
    "height": 750,
    "order": 5
  },
  "pointCloud": {
    "assetType": "image",
    "name": "pointCloud",
    "src": pointCloud,
    "width": 910,
    "height": 384,
    "order": 6
  },
  "solarEnvelope": {
    "assetType": "image",
    "name": "solarEnvelope",
    "src": solarEnvelope,
    "width": 1524,
    "height": 686,
    "order": 7
  }
}

export default assets;