import sunpath from "pages/designAndArch/visualizingClimate/images/sunpath.jpg";
import sunpath_oneHour_allYear_lines from "pages/designAndArch/visualizingClimate/images/sunpath_oneHour_allYear_lines.jpg";
import winter_sun from "pages/designAndArch/visualizingClimate/images/winter_sun.jpg";
import comfort_maps from "pages/designAndArch/visualizingClimate/images/comfort_maps.jpg";
import wind from "pages/designAndArch/visualizingClimate/images/wind.jpg";
import direct_sun_and_wind from "pages/designAndArch/visualizingClimate/images/direct_sun_and_wind.jpg";

const assets = {
  sunpath: {
    assetType: "image",
    name: "sunpath",
    src: sunpath,
    width: 1703,
    height: 500,
    order: 0
  },
  sunpath_oneHour_allYear_lines: {
    assetType: "image",
    name: "sunpath_oneHour_allYear_lines",
    src: sunpath_oneHour_allYear_lines,
    width: 1143,
    height: 1119,
    order: 2
  },
  winter_sun: {
    assetType: "image",
    name: "winter_sun",
    src: winter_sun,
    width: 1703,
    height: 449,
    order: 3
  },
  comfort_maps: {
    assetType: "image",
    name: "comfort_maps",
    src: comfort_maps,
    width: 1696,
    height: 646,
    order: 4
  },
  wind: {
    assetType: "image",
    name: "wind",
    src: wind,
    width: 1703,
    height: 867,
    order: 5
  },
  direct_sun_and_wind: {
    assetType: "image",
    name: "direct_sun_and_wind",
    src: direct_sun_and_wind,
    width: 1703,
    height: 418,
    order: 6
  }
};

export default assets;
