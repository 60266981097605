import React, { useState } from "react";

import styles from "./img.module.scss";

const Img: React.FC<Partial<HTMLImageElement>> = props => {
  const [isLoaded, setLoaded] = useState(false);

  // const [isExpanded, setIsExpanded] = useState(false);
  const isExpanded = false;

  const handleOnLoad = () => {
    setLoaded(true);
  };

  const handleDblClick = () => {
    // setIsExpanded(true);
  };

  const { className, ...rest } = props;

  let height, width;
  if (isExpanded) {
    height = "auto";
    width = "auto";
  } else {
    height = props.height || "auto";
    width = props.width;
  }

  return (
    <img
      className={styles.img + " " + isLoaded && " loaded " + className}
      onDoubleClick={handleDblClick}
      alt={""}
      {...(rest as any)}
      height={height}
      width={width}
      onLoad={handleOnLoad}
    />
  );
};

export default Img;
