import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Home, Linkedin, GitHub } from "react-feather";

import cn from "classnames";
import Hamburger from "hamburger-react";

import { LinkItem, MenuItem, ParentItem } from "../../types";

import styles from "./mobileMenu.module.scss";

export type Props = {
  menuItems: MenuItem[];
};

/*

  some references - https://css-tricks.com/hamburger-menu-with-a-side-of-react-hooks-and-styled-components/
  https://github.com/negomi/react-burger-menu
  
*/

const MobileMenu: React.FC<Props> = ({ menuItems }) => {
  const [selected, setSelected] = useState(false);
  const [menuBackgroundVisible, setMenuBackgroundVisible] = useState(false);
  const handleLinkClick = () => {
    setSelected(false);
  };

  useEffect(() => {
    if (!selected) {
      setTimeout(() => {
        setMenuBackgroundVisible(false);
      }, 500);
    } else {
      setMenuBackgroundVisible(true);
    }
  }, [selected]);

  return (
    <div
      className={cn(styles.mainMenu, {
        [styles.selected]: selected,
        [styles.menuHidden]: menuBackgroundVisible === false
      })}
    >
      <div className={styles.menuOverlay}>
        <div className={styles.menuBtnContainer}>
          <Hamburger label="Show menu" size={28} toggled={selected} toggle={setSelected} />
          <div className={cn(styles.menuContent)}>
            <div onClick={handleLinkClick} className={styles.menuTxt}>
              Menu
            </div>
            <div className={styles.menuItemsBody}>
              <nav aria-label="Site">
                <ul>
                  {menuItems.map(item => {
                    let _item = item as LinkItem;
                    if (_item.link) {
                      return (
                        <li key={_item.link}>
                          <Link onClick={handleLinkClick} to={_item.link}>
                            {item.name}
                          </Link>
                        </li>
                      );
                    } else {
                      let _item = item as ParentItem;
                      return (
                        <li key={_item.name}>
                          <Link
                            onClick={handleLinkClick}
                            to={"/" + _item.base}
                            className={styles.liTitle}
                          >
                            {_item.name}
                          </Link>
                        </li>
                      );
                    }
                  })}
                </ul>
              </nav>
              <div className={styles.links}>
                <div>
                  <Link onClick={handleLinkClick} to={"/"}>
                    <Home />
                  </Link>
                </div>
                <div>
                  <a
                    onClick={handleLinkClick}
                    href={"https://www.linkedin.com/in/boris-plotnikov-9481a012/"}
                    target={"_blank"}
                    rel="noreferrer"
                  >
                    <Linkedin />
                  </a>
                </div>
                <div>
                  <a
                    onClick={handleLinkClick}
                    href={"https://github.com/boris-p/"}
                    target={"_blank"}
                    rel="noreferrer"
                  >
                    <GitHub />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default MobileMenu;
