import rendering from "pages/computation/elytraPavilion/images/rendering.jpg";
import microClimate from "pages/computation/elytraPavilion/images/microClimate.jpg";
import movement_patterns from "pages/computation/elytraPavilion/images/movement_patterns.jpg";

const assets = {
  rendering: {
    assetType: "image",
    name: "rendering",
    src: rendering,
    width: 960,
    height: 720,
    order: 0
  },
  microClimate: {
    assetType: "image",
    name: "microClimate",
    src: microClimate,
    width: 961,
    height: 700,
    order: 1
  },
  movement_patterns: {
    assetType: "image",
    name: "movement_patterns",
    src: movement_patterns,
    width: 961,
    height: 700,
    order: 2
  }
};

export default assets;
