import React from "react";
import { Link } from "react-router-dom";
import { LinkItem, MenuItem, ParentItem } from "../../types";

import LiLink from "../LiLink";

import styles from "./regularMenu.module.scss";

type Props = {
  menuItems: MenuItem[];
};

const RegularMenu: React.FC<Props> = ({ menuItems }) => {
  return (
    <div className={styles.mainMenu}>
      <div className={styles.linkToHome}>
        <Link to={`/`}>Home</Link>
      </div>
      <nav aria-label="Site">
        <ul>
          {menuItems.map(item => {
            let _item = item as LinkItem;
            if (_item.link) {
              return (
                <li key={_item.link}>
                  <Link to={_item.link}>{item.name}</Link>
                </li>
              );
            } else {
              let _item = item as ParentItem;
              return (
                <li key={_item.name}>
                  <div>
                    <div className={styles.liTitle}>
                      <span>{_item.name}</span>
                    </div>
                    <ul className={styles.secondLevel}>
                      {Object.values(_item.children).map((page: any) => {
                        return <LiLink key={page.name} base={_item.base} page={page} />;
                      })}
                    </ul>
                  </div>
                </li>
              );
            }
          })}
        </ul>
      </nav>
    </div>
  );
};
export default RegularMenu;
