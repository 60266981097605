import sketch_cdp_1 from "pages/computation/cdp/images/sketch_cdp_1.png";
import sketch_cdp from "pages/computation/cdp/images/sketch_cdp.jpg";
import cdp2 from "pages/computation/cdp/images/cdp2.png";
import cdp1 from "pages/computation/cdp/images/cdp1.png";
import cdp1_new from "pages/computation/cdp/images/cdp1_new.png";


const assets = {
  "sketch_cdp_1": {
    "assetType": "image",
    "name": "sketch_cdp_1",
    "src": sketch_cdp_1,
    "width": 800,
    "height": 514,
    "order": 1
  },
  "sketch_cdp": {
    "assetType": "image",
    "name": "sketch_cdp",
    "src": sketch_cdp,
    "width": 800,
    "height": 514,
    "order": 2
  },
  "cdp2": {
    "assetType": "image",
    "name": "cdp2",
    "src": cdp2,
    "width": 2428,
    "height": 1296,
    "order": 3
  },
  "cdp1": {
    "assetType": "image",
    "name": "cdp1",
    "src": cdp1,
    "width": 1726,
    "height": 1282,
    "order": 4
  },
  "cdp1_new": {
    "assetType": "image",
    "name": "cdp1_new",
    "src": cdp1_new,
    "width": 1726,
    "height": 1282,
    "order": 5
  }
}

export default assets;