import React, { Suspense, lazy } from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import Spinner from "components/spinner";
import links from "pages/app/links";

const Carving = lazy(() => import("pages/art/carving"));

const ArtRouter: React.FC = () => {
  const base = links.art.link;

  const l = (link: string) => `/${base}/${link}`;

  return (
    <Suspense fallback={<Spinner />}>
      <Switch>
        <Redirect exact path={`/${base}`} to={l(links.art.children.carving.link)} />
        <Route path={l(links.art.children.carving.link)} component={Carving} />
      </Switch>
    </Suspense>
  );
};

export default ArtRouter;
