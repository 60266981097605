import React from "react";
import { Link } from "react-router-dom";

type LiLinkProps = {
  page: {
    link: string;
    name: string;
  };
  base: string;
};

const LiLink: React.FC<LiLinkProps> = ({ base, page }) => {
  const { link, name } = page;
  return (
    <li>
      <Link to={`/${base}/${link}`}>{name}</Link>
    </li>
  );
};

export default LiLink;
