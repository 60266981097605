// design and arch
import e18TowersAssets from "../designAndArch/e18Towers/images/gallery";
import acreMuseumAssets from "../designAndArch/acreMuseum/assets/images/gallery";
import futuromeAssets from "../designAndArch/futurome/images/gallery";
import apartmentExplorationsAssets from "../designAndArch/apartmentExplorations/images/gallery";
import theUnknownLandAssets from "../designAndArch/theUnknownLand/assets/images/gallery";
import visualizingClimateAssets from "../designAndArch/visualizingClimate/images/gallery";

// computation
import elytraPavilionAssets from "../computation/elytraPavilion/images/gallery";
import cdpAssets from "../computation/cdp/images/gallery";
import solarEnvelopesAssets from "../computation/parametricSolarEnvelopes/images/gallery";
import luxAssets from "../computation/lux/images/gallery";

import carvingAssets from "../art/carving/images/gallery";

const data = {
  designAndArch: {
    e18TowersAssets,
    acreMuseumAssets,
    futuromeAssets,
    apartmentExplorationsAssets,
    theUnknownLandAssets,
    visualizingClimateAssets
  },
  computation: { elytraPavilionAssets, cdpAssets, solarEnvelopesAssets, luxAssets },
  art: { carvingAssets }
};

export default data;
