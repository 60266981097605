import buildings_plan from "pages/designAndArch/e18Towers/images/buildings_plan.jpg";
import site_roof_plan from "pages/designAndArch/e18Towers/images/site_roof_plan.jpg";
import section_3 from "pages/designAndArch/e18Towers/images/section_3.jpg";
import section_2 from "pages/designAndArch/e18Towers/images/section_2.jpg";
import building_inner_facade_lines from "pages/designAndArch/e18Towers/images/building_inner_facade_lines.jpg";
import section_1 from "pages/designAndArch/e18Towers/images/section_1.jpg";
import buildings_plan_rotated from "pages/designAndArch/e18Towers/images/buildings_plan_rotated.jpg";
import exterior_building_facade_lines from "pages/designAndArch/e18Towers/images/exterior_building_facade_lines.jpg";
import outside_rendering_1 from "pages/designAndArch/e18Towers/images/outside_rendering_1.jpg";
import apartment_combinations from "pages/designAndArch/e18Towers/images/apartment_combinations.jpg";
import apartments_plan_and_section_sketch from "pages/designAndArch/e18Towers/images/apartments_plan_and_section_sketch.jpg";
import main_park_rendering from "pages/designAndArch/e18Towers/images/main_park_rendering.jpg";
import apartments_perspective_and_plan from "pages/designAndArch/e18Towers/images/apartments_perspective_and_plan.jpg";
import site_plan from "pages/designAndArch/e18Towers/images/site_plan.jpg";
import from_se_to_apartments_3 from "pages/designAndArch/e18Towers/images/sun_envelopes_to_apartments/from_se_to_apartments_3.jpg";
import from_se_to_apartments_1 from "pages/designAndArch/e18Towers/images/sun_envelopes_to_apartments/from_se_to_apartments_1.jpg";
import from_se_to_apartments_4 from "pages/designAndArch/e18Towers/images/sun_envelopes_to_apartments/from_se_to_apartments_4.jpg";
import from_se_to_apartments from "pages/designAndArch/e18Towers/images/sun_envelopes_to_apartments/from_se_to_apartments.jpg";
import inner_courtyard_rendering from "pages/designAndArch/e18Towers/images/inner_courtyard_rendering.jpg";


const assets = {
  "buildings_plan": {
    "assetType": "image",
    "name": "buildings_plan",
    "src": buildings_plan,
    "width": 1762,
    "height": 2047,
    "order": 0
  },
  "site_roof_plan": {
    "assetType": "image",
    "name": "site_roof_plan",
    "src": site_roof_plan,
    "width": 2598,
    "height": 3111,
    "order": 2
  },
  "section_3": {
    "assetType": "image",
    "name": "section_3",
    "src": section_3,
    "width": 3465,
    "height": 2011,
    "order": 3
  },
  "section_2": {
    "assetType": "image",
    "name": "section_2",
    "src": section_2,
    "width": 4331,
    "height": 2162,
    "order": 4
  },
  "building_inner_facade_lines": {
    "assetType": "image",
    "name": "building_inner_facade_lines",
    "src": building_inner_facade_lines,
    "width": 3150,
    "height": 1895,
    "order": 5
  },
  "section_1": {
    "assetType": "image",
    "name": "section_1",
    "src": section_1,
    "width": 4331,
    "height": 1958,
    "order": 6
  },
  "buildings_plan_rotated": {
    "assetType": "image",
    "name": "buildings_plan_rotated",
    "src": buildings_plan_rotated,
    "width": 2047,
    "height": 1762,
    "order": 7
  },
  "exterior_building_facade_lines": {
    "assetType": "image",
    "name": "exterior_building_facade_lines",
    "src": exterior_building_facade_lines,
    "width": 3150,
    "height": 1288,
    "order": 8
  },
  "outside_rendering_1": {
    "assetType": "image",
    "name": "outside_rendering_1",
    "src": outside_rendering_1,
    "width": 2362,
    "height": 1440,
    "order": 9
  },
  "apartment_combinations": {
    "assetType": "image",
    "name": "apartment_combinations",
    "src": apartment_combinations,
    "width": 3150,
    "height": 2085,
    "order": 10
  },
  "apartments_plan_and_section_sketch": {
    "assetType": "image",
    "name": "apartments_plan_and_section_sketch",
    "src": apartments_plan_and_section_sketch,
    "width": 1929,
    "height": 1379,
    "order": 11
  },
  "main_park_rendering": {
    "assetType": "image",
    "name": "main_park_rendering",
    "src": main_park_rendering,
    "width": 2756,
    "height": 2652,
    "order": 12
  },
  "apartments_perspective_and_plan": {
    "assetType": "image",
    "name": "apartments_perspective_and_plan",
    "src": apartments_perspective_and_plan,
    "width": 1850,
    "height": 1080,
    "order": 13
  },
  "site_plan": {
    "assetType": "image",
    "name": "site_plan",
    "src": site_plan,
    "width": 2362,
    "height": 2663,
    "order": 14
  },
  "sun_envelopes_to_apartments": {
    "assetType": "imageSequence",
    "name": "sun_envelopes_to_apartments",
    "width": 2094,
    "height": 1556,
    "assets": {
      "from_se_to_apartments_3": {
        "assetType": "image",
        "name": "from_se_to_apartments_3",
        "src": from_se_to_apartments_3,
        "width": 2094,
        "height": 906,
        "order": 0
      },
      "from_se_to_apartments_1": {
        "assetType": "image",
        "name": "from_se_to_apartments_1",
        "src": from_se_to_apartments_1,
        "width": 2094,
        "height": 919,
        "order": 1
      },
      "from_se_to_apartments_4": {
        "assetType": "image",
        "name": "from_se_to_apartments_4",
        "src": from_se_to_apartments_4,
        "width": 2094,
        "height": 1556,
        "order": 2
      },
      "from_se_to_apartments": {
        "assetType": "image",
        "name": "from_se_to_apartments",
        "src": from_se_to_apartments,
        "width": 2094,
        "height": 1089,
        "order": 3
      }
    },
    "sequenceType": "row",
    "order": 15
  },
  "inner_courtyard_rendering": {
    "assetType": "image",
    "name": "inner_courtyard_rendering",
    "src": inner_courtyard_rendering,
    "width": 2756,
    "height": 2157,
    "order": 16
  }
}

export default assets;