import React, { Suspense, lazy } from "react";
import { Route, Switch } from "react-router-dom";

import Spinner from "components/spinner";
import links from "pages/app/links";
import RegularPage from "templates/regularPage";
import DesignAndArchIndex from ".";

const AcreMuseum = lazy(() => import("pages/designAndArch/acreMuseum"));
const ApartmentExplorations = lazy(() => import("pages/designAndArch/apartmentExplorations"));
const E18Towers = lazy(() => import("pages/designAndArch/e18Towers"));
const futurome = lazy(() => import("pages/designAndArch/futurome"));
const TheUnknownLand = lazy(() => import("pages/designAndArch/theUnknownLand"));
const VisualizingClimate = lazy(() => import("pages/designAndArch/visualizingClimate"));

const DesignAndArchRouter: React.FC = () => {
  const base = links.designAndArch.link;

  const l = (link: string) => `/${base}/${link}`;

  return (
    <RegularPage>
      <Suspense fallback={<Spinner />}>
        <Switch>
          <Route exact path={`/${base}`} component={DesignAndArchIndex} />
          <Route path={l(links.designAndArch.children.acreMuseum.link)} component={AcreMuseum} />
          <Route
            path={l(links.designAndArch.children.apartmentExplorations.link)}
            component={ApartmentExplorations}
          />
          <Route path={l(links.designAndArch.children.e18Towers.link)} component={E18Towers} />
          <Route path={l(links.designAndArch.children.futurome.link)} component={futurome} />
          <Route
            path={l(links.designAndArch.children.theUnknownLand.link)}
            component={TheUnknownLand}
          />
          <Route
            path={l(links.designAndArch.children.visualizingClimate.link)}
            component={VisualizingClimate}
          />
        </Switch>
      </Suspense>
    </RegularPage>
  );
};

export default DesignAndArchRouter;
