import misc_a from "pages/art/carving/images/misc/misc_a.jpg";
import dog_a from "pages/art/carving/images/dog/dog_a.jpg";
import dog_c from "pages/art/carving/images/dog/dog_c.jpg";
import dog_b from "pages/art/carving/images/dog/dog_b.jpg";
import dog_e from "pages/art/carving/images/dog/dog_e.jpg";
import dog_d from "pages/art/carving/images/dog/dog_d.jpg";
import red_a from "pages/art/carving/images/red/red_a.jpg";
import red_c from "pages/art/carving/images/red/red_c.jpg";
import red_b from "pages/art/carving/images/red/red_b.jpg";
import red_d from "pages/art/carving/images/red/red_d.jpg";
import white_d from "pages/art/carving/images/white/white_d.jpg";
import white_c from "pages/art/carving/images/white/white_c.jpg";
import white_b from "pages/art/carving/images/white/white_b.jpg";
import white_a from "pages/art/carving/images/white/white_a.jpg";

const assets = {
  misc: {
    assetType: "imageSequence",
    name: "misc",
    width: 2048,
    height: 1340,
    assets: {
      misc_a: {
        assetType: "image",
        name: "misc_a",
        src: misc_a,
        width: 2048,
        height: 1340,
        order: 0
      }
    },
    sequenceType: "row",
    order: 0
  },
  dog: {
    assetType: "imageSequence",
    name: "dog",
    width: 2048,
    height: 1367,
    assets: {
      dog_a: {
        assetType: "image",
        name: "dog_a",
        src: dog_a,
        width: 2048,
        height: 1367,
        order: 0
      },
      dog_c: {
        assetType: "image",
        name: "dog_c",
        src: dog_c,
        width: 2048,
        height: 1367,
        order: 1
      },
      dog_b: {
        assetType: "image",
        name: "dog_b",
        src: dog_b,
        width: 2048,
        height: 1367,
        order: 2
      },
      dog_e: {
        assetType: "image",
        name: "dog_e",
        src: dog_e,
        width: 2048,
        height: 1367,
        order: 3
      },
      dog_d: {
        assetType: "image",
        name: "dog_d",
        src: dog_d,
        width: 2048,
        height: 1367,
        order: 4
      }
    },
    sequenceType: "row",
    order: 2
  },
  red: {
    assetType: "imageSequence",
    name: "red",
    width: 2048,
    height: 1366,
    assets: {
      red_a: {
        assetType: "image",
        name: "red_a",
        src: red_a,
        width: 2048,
        height: 1366,
        order: 0
      },
      red_c: {
        assetType: "image",
        name: "red_c",
        src: red_c,
        width: 2048,
        height: 1366,
        order: 1
      },
      red_b: {
        assetType: "image",
        name: "red_b",
        src: red_b,
        width: 2048,
        height: 1366,
        order: 2
      },
      red_d: {
        assetType: "image",
        name: "red_d",
        src: red_d,
        width: 2048,
        height: 1366,
        order: 3
      }
    },
    sequenceType: "row",
    order: 3
  },
  white: {
    assetType: "imageSequence",
    name: "white",
    width: 2048,
    height: 2048,
    assets: {
      white_d: {
        assetType: "image",
        name: "white_d",
        src: white_d,
        width: 1366,
        height: 2048,
        order: 0
      },
      white_c: {
        assetType: "image",
        name: "white_c",
        src: white_c,
        width: 1366,
        height: 2048,
        order: 1
      },
      white_b: {
        assetType: "image",
        name: "white_b",
        src: white_b,
        width: 2048,
        height: 1366,
        order: 2
      },
      white_a: {
        assetType: "image",
        name: "white_a",
        src: white_a,
        width: 1366,
        height: 2048,
        order: 3
      }
    },
    sequenceType: "row",
    order: 4
  }
};

export default assets;
