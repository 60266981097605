import facade_2 from "pages/designAndArch/acreMuseum/assets/images/facade_2.jpg";
import perspective_render_without_walls from "pages/designAndArch/acreMuseum/assets/images/perspective_render_without_walls.png";
import facade_1 from "pages/designAndArch/acreMuseum/assets/images/facade_1.jpg";
import perspective_render_with_walls from "pages/designAndArch/acreMuseum/assets/images/perspective_render_with_walls.png";
import final_rendering from "pages/designAndArch/acreMuseum/assets/images/final_rendering.jpg";
import abstract_canopy from "pages/designAndArch/acreMuseum/assets/images/abstract_canopy.png";
import wireframes_perspective_rendering from "pages/designAndArch/acreMuseum/assets/images/wireframes_perspective_rendering.jpg";

const assets = {
  facade_2: {
    assetType: "image",
    name: "facade_2",
    src: facade_2,
    width: 2219,
    height: 946,
    order: 0
  },
  perspective_render_without_walls: {
    assetType: "image",
    name: "perspective_render_without_walls",
    src: perspective_render_without_walls,
    width: 1600,
    height: 743,
    order: 1
  },
  facade_1: {
    assetType: "image",
    name: "facade_1",
    src: facade_1,
    width: 2139,
    height: 1039,
    order: 2
  },
  perspective_render_with_walls: {
    assetType: "image",
    name: "perspective_render_with_walls",
    src: perspective_render_with_walls,
    width: 1600,
    height: 743,
    order: 4
  },
  not_used: {
    assetType: "imageSequence",
    name: "not_used",
    width: 5555,
    height: 2045,
    assets: {
      facade_2: {
        assetType: "image",
        name: "facade_2",
        src: facade_2,
        width: 5375,
        height: 2045,
        order: 0
      },
      facade_1: {
        assetType: "image",
        name: "facade_1",
        src: facade_1,
        width: 5555,
        height: 2045,
        order: 1
      },
      final_rendering: {
        assetType: "image",
        name: "final_rendering",
        src: final_rendering,
        width: 2000,
        height: 1749,
        order: 2
      }
    },
    sequenceType: "row",
    order: 5
  },
  abstract_canopy: {
    assetType: "image",
    name: "abstract_canopy",
    src: abstract_canopy,
    width: 3000,
    height: 855,
    order: 6
  },
  wireframes_perspective_rendering: {
    assetType: "image",
    name: "wireframes_perspective_rendering",
    src: wireframes_perspective_rendering,
    width: 3000,
    height: 1500,
    order: 7
  },
  final_rendering: {
    assetType: "image",
    name: "final_rendering",
    src: final_rendering,
    width: 2000,
    height: 2115,
    order: 8
  }
};

export default assets;
