import React from "react";

// import config from 'config'

import RootStore from "store/rootStore";
import { StoreProvider } from "store/context";

import Routes from "./Routes";

const store = new RootStore();

const App: React.FC = () => {
  return (
    <StoreProvider value={store}>
      <Routes />
    </StoreProvider>
  );
};

export default App;
