import classNames from "classnames";
import { useMediaQuery } from "react-responsive";

import styles from "./styles.module.scss";

type ComponentTypes = { className?: string };

type LinkProps = ComponentTypes & {
  href: string;
  target?: string;
};

export const RegPage: React.FC<ComponentTypes> = ({ children, className }) => {
  return <div className={classNames(styles.regPage, className)}>{children}</div>;
};

export const WidePage: React.FC<ComponentTypes> = ({ children, className }) => {
  return <div className={classNames(styles.widePage, className)}>{children}</div>;
};

export const RegPageBody: React.FC<ComponentTypes> = ({ children, className }) => {
  const isSmallScreen = useMediaQuery({ query: "(max-width: 1224px)" });
  let classes = isSmallScreen ? classNames("mb-6", className) : classNames("ml-7 mb-6", className);
  return <div className={classes}>{children}</div>;
};

export const Title: React.FC<ComponentTypes> = ({ children, className }) => {
  return <h1 className={styles.title + " " + className}>{children}</h1>;
};

export const DarkTitle: React.FC<ComponentTypes> = ({ children, className }) => {
  return <h1 className={styles.darkTitle + " " + className}>{children}</h1>;
};

export const SubTitle: React.FC<ComponentTypes> = ({ children, className }) => {
  const isSmallScreen = useMediaQuery({ query: "(max-width: 1224px)" });
  return (
    <h3 className={classNames(styles.subTitle, className, { [styles.smallScreen]: isSmallScreen })}>
      {children}
    </h3>
  );
};

export const TopDescription: React.FC<ComponentTypes> = ({ children, className }) => {
  return <div className={styles.topDescription + " " + className}>{children}</div>;
};

export const RedBold: React.FC<ComponentTypes> = ({ children, className }) => {
  return <span className={styles.redBold + " " + className}>{children}</span>;
};

export const Block: React.FC<ComponentTypes> = ({ children, className = "" }) => {
  return <div className={styles.block + " " + className}>{children}</div>;
};

export const MediumP: React.FC<ComponentTypes> = ({ children, className }) => {
  const isSmallScreen = useMediaQuery({ query: "(max-width: 1224px)" });
  return (
    <p
      className={classNames(styles.mediumP, className, {
        [styles.smallScreen]: isSmallScreen
      })}
    >
      {children}
    </p>
  );
};

export const SideComment: React.FC<ComponentTypes> = ({ children, className }) => {
  return <div className={styles.sideComment + " " + className}>{children}</div>;
};

export const LinkBtn: React.FC<LinkProps> = ({ children, className, href, target = "_blank" }) => {
  return (
    <div className={styles.linkBtn + " " + className}>
      <a href={href} target={target}>
        {children}
      </a>
    </div>
  );
};
