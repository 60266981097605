import { makeObservable, observable, computed } from "mobx";
import RootStore from "./rootStore";

export default class ObservableUserStore {
  private rootStore: RootStore;

  public strings: string[];
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;

    makeObservable(this, {
      strings: observable,
      texts: computed
    });

    this.strings = [];
  }

  get texts() {
    return this.strings;
  }

  test(str: string) {
    this.strings.push(str);
  }
}
