import dynamicLighting from "pages/computation/lux/images/dynamicLighting.jpg";
import energyUse from "pages/computation/lux/images/energyUse.jpg";
import germanyMap from "pages/computation/lux/images/germanyMap.jpg";
import radiationMap from "pages/computation/lux/images/radiationMap.jpg";
import perspectiveView from "pages/computation/lux/images/perspectiveView.jpg";
import sectionView from "pages/computation/lux/images/sectionView.jpg";
import interiorLighting from "pages/computation/lux/images/interiorLighting.jpg";

const assets = {
  dynamicLighting: {
    assetType: "image",
    name: "dynamicLighting",
    src: dynamicLighting,
    width: 1235,
    height: 966,
    order: 0
  },
  energyUse: {
    assetType: "image",
    name: "energyUse",
    src: energyUse,
    width: 1550,
    height: 905,
    order: 1
  },
  germanyMap: {
    assetType: "image",
    name: "germanyMap",
    src: germanyMap,
    width: 1311,
    height: 771,
    order: 2
  },
  radiationMap: {
    assetType: "image",
    name: "radiationMap",
    src: radiationMap,
    width: 1248,
    height: 722,
    order: 3
  },
  perspectiveView: {
    assetType: "image",
    name: "perspectiveView",
    src: perspectiveView,
    width: 1479,
    height: 833,
    order: 4
  },
  sectionView: {
    assetType: "image",
    name: "sectionView",
    src: sectionView,
    width: 328,
    height: 470,
    order: 5
  },
  interiorLighting: {
    assetType: "image",
    name: "interiorLighting",
    src: interiorLighting,
    width: 1265,
    height: 957,
    order: 6
  }
};

export default assets;
