import React, { Suspense, lazy } from "react";
import { Route, Switch } from "react-router-dom";

import Spinner from "components/spinner";
import links from "pages/app/links";
import RegularPage from "templates/regularPage";
import ComputationIndex from ".";

const Cdp = lazy(() => import("pages/computation/cdp"));
const ElytraPavilion = lazy(() => import("pages/computation/elytraPavilion"));
const Lux = lazy(() => import("pages/computation/lux"));
const ParametricSolarEnvelopes = lazy(() => import("pages/computation/parametricSolarEnvelopes"));
const Plotbot = lazy(() => import("pages/computation/plotBot"));

const ComputationRouter: React.FC = () => {
  const base = links.computation.link;

  const l = (link: string) => `/${base}/${link}`;

  const { children } = links.computation;

  return (
    <RegularPage>
      <Suspense fallback={<Spinner />}>
        <Switch>
          <Route exact path={`/${base}`} component={ComputationIndex} />
          <Route path={l(children.cdp.link)} component={Cdp} />
          <Route path={l(children.elytraPavilion.link)} component={ElytraPavilion} />
          <Route path={l(children.lux.link)} component={Lux} />
          <Route path={l(children.solarEnvelopes.link)} component={ParametricSolarEnvelopes} />
          <Route path={l(children.plotBot.link)} component={Plotbot} />
        </Switch>
      </Suspense>
    </RegularPage>
  );
};

export default ComputationRouter;
