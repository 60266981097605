import _02_daylight_export from "pages/designAndArch/futurome/images/climate_simulations/_02_daylight_export.jpg";
import _01_climate_simulations from "pages/designAndArch/futurome/images/climate_simulations/_01_climate_simulations.jpg";
import _11_indoor_rendering_2 from "pages/designAndArch/futurome/images/_11_indoor_rendering_2.jpg";
import _05_sketches from "pages/designAndArch/futurome/images/_05_sketches.jpg";
import birds_eye_edited from "pages/designAndArch/futurome/images/birds_eye_edited.jpg";
import _03_diagram_functions from "pages/designAndArch/futurome/images/_03_diagram_functions.jpg";
import urban_plan from "pages/designAndArch/futurome/images/urban_plan.jpg";
import facade_detailed from "pages/designAndArch/futurome/images/facade/facade_detailed.jpg";
import facade_system from "pages/designAndArch/futurome/images/facade/facade_system.jpg";
import section from "pages/designAndArch/futurome/images/section.jpg";
import neighborhood_plan from "pages/designAndArch/futurome/images/neighborhood_plan.jpg";
import _07_outdoor_plaza_movement_pers from "pages/designAndArch/futurome/images/_07_outdoor_plaza_movement_pers.jpg";
import _03_rendered from "pages/designAndArch/futurome/images/plaza/_03_rendered.jpg";
import _01_ghosted from "pages/designAndArch/futurome/images/plaza/_01_ghosted.jpg";
import _04_final_rendering from "pages/designAndArch/futurome/images/plaza/_04_final_rendering.jpg";
import _02_shaded from "pages/designAndArch/futurome/images/plaza/_02_shaded.jpg";
import _08_outdoor_section_climate_concept from "pages/designAndArch/futurome/images/_08_outdoor_section_climate_concept.jpg";
import _04_use_diagram from "pages/designAndArch/futurome/images/_04_use_diagram.jpg";
import _12_indoor_rendering from "pages/designAndArch/futurome/images/_12_indoor_rendering.jpg";
import _10_building_section_concept from "pages/designAndArch/futurome/images/_10_building_section_concept.jpg";
import _01_birds_eye_rendering from "pages/designAndArch/futurome/images/_01_birds_eye_rendering.jpg";
import _02_sketch from "pages/designAndArch/futurome/images/_02_sketch.jpg";
import another_roof_plan from "pages/designAndArch/futurome/images/another_roof_plan.jpg";
import _06_sketches from "pages/designAndArch/futurome/images/_06_sketches.jpg";
import _09_outdoor_section_peope_visibility from "pages/designAndArch/futurome/images/_09_outdoor_section_peope_visibility.jpg";

const assets = {
  climate_simulations: {
    assetType: "imageSequence",
    name: "climate_simulations",
    width: 4676,
    height: 2763,
    assets: {
      _02_daylight_export: {
        assetType: "image",
        name: "_02_daylight_export",
        src: _02_daylight_export,
        width: 4676,
        height: 2763,
        order: 0
      },
      _01_climate_simulations: {
        assetType: "image",
        name: "_01_climate_simulations",
        src: _01_climate_simulations,
        width: 1644,
        height: 1160,
        order: 1
      }
    },
    sequenceType: "row",
    order: 0
  },
  _11_indoor_rendering_2: {
    assetType: "image",
    name: "_11_indoor_rendering_2",
    src: _11_indoor_rendering_2,
    width: 1653,
    height: 562,
    order: 1
  },
  _05_sketches: {
    assetType: "image",
    name: "_05_sketches",
    src: _05_sketches,
    width: 1549,
    height: 687,
    order: 3
  },
  birds_eye_edited: {
    assetType: "image",
    name: "birds_eye_edited",
    src: birds_eye_edited,
    width: 1469,
    height: 3000,
    order: 4
  },
  _03_diagram_functions: {
    assetType: "image",
    name: "_03_diagram_functions",
    src: _03_diagram_functions,
    width: 1580,
    height: 1146,
    order: 5
  },
  urban_plan: {
    assetType: "image",
    name: "urban_plan",
    src: urban_plan,
    width: 894,
    height: 1174,
    order: 6
  },
  facade: {
    assetType: "imageSequence",
    name: "facade",
    width: 1641,
    height: 1388,
    assets: {
      facade_detailed: {
        assetType: "image",
        name: "facade_detailed",
        src: facade_detailed,
        width: 1333,
        height: 1388,
        order: 0
      },
      facade_system: {
        assetType: "image",
        name: "facade_system",
        src: facade_system,
        width: 1641,
        height: 1125,
        order: 1
      }
    },
    sequenceType: "row",
    order: 7
  },
  section: {
    assetType: "image",
    name: "section",
    src: section,
    width: 1558,
    height: 744,
    order: 8
  },
  neighborhood_plan: {
    assetType: "image",
    name: "neighborhood_plan",
    src: neighborhood_plan,
    width: 2000,
    height: 1549,
    order: 9
  },
  _07_outdoor_plaza_movement_pers: {
    assetType: "image",
    name: "_07_outdoor_plaza_movement_pers",
    src: _07_outdoor_plaza_movement_pers,
    width: 3413,
    height: 1657,
    order: 10
  },
  plaza: {
    assetType: "imageSequence",
    name: "plaza",
    width: 3000,
    height: 1469,
    assets: {
      _03_rendered: {
        assetType: "image",
        name: "_03_rendered",
        src: _03_rendered,
        width: 2000,
        height: 979,
        order: 0
      },
      _01_ghosted: {
        assetType: "image",
        name: "_01_ghosted",
        src: _01_ghosted,
        width: 1474,
        height: 736,
        order: 1
      },
      _04_final_rendering: {
        assetType: "image",
        name: "_04_final_rendering",
        src: _04_final_rendering,
        width: 3000,
        height: 1469,
        order: 2
      },
      _02_shaded: {
        assetType: "image",
        name: "_02_shaded",
        src: _02_shaded,
        width: 1474,
        height: 736,
        order: 3
      }
    },
    sequenceType: "row",
    order: 11
  },
  _08_outdoor_section_climate_concept: {
    assetType: "image",
    name: "_08_outdoor_section_climate_concept",
    src: _08_outdoor_section_climate_concept,
    width: 4961,
    height: 3508,
    order: 12
  },
  _04_use_diagram: {
    assetType: "image",
    name: "_04_use_diagram",
    src: _04_use_diagram,
    width: 1499,
    height: 1861,
    order: 13
  },
  _12_indoor_rendering: {
    assetType: "image",
    name: "_12_indoor_rendering",
    src: _12_indoor_rendering,
    width: 1659,
    height: 826,
    order: 14
  },
  _10_building_section_concept: {
    assetType: "image",
    name: "_10_building_section_concept",
    src: _10_building_section_concept,
    width: 1646,
    height: 1155,
    order: 15
  },
  _01_birds_eye_rendering: {
    assetType: "image",
    name: "_01_birds_eye_rendering",
    src: _01_birds_eye_rendering,
    width: 2801,
    height: 1469,
    order: 16
  },
  _02_sketch: {
    assetType: "image",
    name: "_02_sketch",
    src: _02_sketch,
    width: 1637,
    height: 1156,
    order: 17
  },
  another_roof_plan: {
    assetType: "image",
    name: "another_roof_plan",
    src: another_roof_plan,
    width: 6214,
    height: 3818,
    order: 18
  },
  _06_sketches: {
    assetType: "image",
    name: "_06_sketches",
    src: _06_sketches,
    width: 1609,
    height: 539,
    order: 19
  },
  _09_outdoor_section_peope_visibility: {
    assetType: "image",
    name: "_09_outdoor_section_peope_visibility",
    src: _09_outdoor_section_peope_visibility,
    width: 1333,
    height: 805,
    order: 20
  }
};

export default assets;
