import type_2_rendering from "pages/designAndArch/apartmentExplorations/images/type_2_rendering.jpg";
import type_1_apartment from "pages/designAndArch/apartmentExplorations/images/type_1_apartment.jpg";
import type_2_apartment from "pages/designAndArch/apartmentExplorations/images/type_2_apartment.jpg";
import type_1_rendering from "pages/designAndArch/apartmentExplorations/images/type_1_rendering.jpg";
import type_3_apartment from "pages/designAndArch/apartmentExplorations/images/type_3_apartment.jpg";
import type_4_rendering from "pages/designAndArch/apartmentExplorations/images/type_4_rendering.jpg";
import type_3_rendering from "pages/designAndArch/apartmentExplorations/images/type_3_rendering.jpg";
import type_4_apartment from "pages/designAndArch/apartmentExplorations/images/type_4_apartment.jpg";


const assets = {
  "type_2_rendering": {
    "assetType": "image",
    "name": "type_2_rendering",
    "src": type_2_rendering,
    "width": 2532,
    "height": 1556,
    "order": 1
  },
  "type_1_apartment": {
    "assetType": "image",
    "name": "type_1_apartment",
    "src": type_1_apartment,
    "width": 1384,
    "height": 896,
    "order": 2
  },
  "type_2_apartment": {
    "assetType": "image",
    "name": "type_2_apartment",
    "src": type_2_apartment,
    "width": 880,
    "height": 835,
    "order": 3
  },
  "type_1_rendering": {
    "assetType": "image",
    "name": "type_1_rendering",
    "src": type_1_rendering,
    "width": 1475,
    "height": 1311,
    "order": 4
  },
  "type_3_apartment": {
    "assetType": "image",
    "name": "type_3_apartment",
    "src": type_3_apartment,
    "width": 1666,
    "height": 775,
    "order": 5
  },
  "type_4_rendering": {
    "assetType": "image",
    "name": "type_4_rendering",
    "src": type_4_rendering,
    "width": 2378,
    "height": 1462,
    "order": 6
  },
  "type_3_rendering": {
    "assetType": "image",
    "name": "type_3_rendering",
    "src": type_3_rendering,
    "width": 2922,
    "height": 2038,
    "order": 7
  },
  "type_4_apartment": {
    "assetType": "image",
    "name": "type_4_apartment",
    "src": type_4_apartment,
    "width": 1659,
    "height": 776,
    "order": 8
  }
}

export default assets;