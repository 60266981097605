import React from "react";

import styles from "./style.module.scss";

type Props = {
  name: string;
  title?: string;
  subTitle?: string;
};
const RouterIndexHeader: React.FC<Props> = ({ name, title, subTitle }) => {
  return (
    <div className={styles.routerIndexHeader}>
      <div className={styles.name}>{name}</div>
      <div className={styles.title}>{title}</div>
      {/* <div className={styles.subTitle}>{subTitle}</div> */}
    </div>
  );
};

export default RouterIndexHeader;
