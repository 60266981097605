import React from "react";
import { Link } from "react-router-dom";

import Asset from "components/uiElements/Asset";
import { Block, RegPage, RegPageBody } from "components/uiElements/projectElements";
import { ElementData } from "pages/app/routersLinksSetup";

import style from "./style.module.scss";
import RouterIndexHeader from "templates/routerIndex/header";

const DesignAndArchIndex: React.FC = () => {
  const designAndArchData = ElementData.designAndArch;

  return (
    <RegPage>
      <RegPageBody>
        <RouterIndexHeader
          name="Design & Architecture"
          title="Conceptual Architecture design prjects from my time in Uni focusing mostly on housing, urban design and sustainable climate driven design."
        />
        <Block>
          {Object.values(designAndArchData).map(link => {
            return (
              <div key={link.asset.name} className={style.item}>
                <Link to={link.link}>
                  <div>
                    <Asset asset={link.asset} widthOverride={240} className={style.asset} />
                  </div>
                  <div className={style.name}>{link.metadata.name}</div>
                  <div className={style.title}>{(link.metadata as any).title || ""}</div>
                </Link>
              </div>
            );
          })}
        </Block>
      </RegPageBody>
    </RegPage>
  );
};
export default DesignAndArchIndex;
