const links = {
  designAndArch: {
    name: "Design & Architecture",
    link: "design-and-arch",
    children: {
      futurome: {
        name: "Futurome",
        link: "futurome",
        title: `A renovation project in midtown Rome, the future of three office towers built in Rome during the 70's`,
        subTitle:
          "The project focused on a sustainable shared housing and work environment, creating public plazas on multiple levels, in varying degrees of privacy"
      },
      theUnknownLand: {
        name: "The Unknown Land",
        link: "the-unknown-land"
      },
      acreMuseum: {
        name: "Acre Museum",
        link: "acre-museum",
        title: "A restoration of a historic structure turned to a Museum by the sea in Acre",
        subTitle:
          "The basic design idea centered around preserving the original structure intact and emphasize it with a lightweight tensile steel canopy was developed with the help of form finding tools in Rhino/Grasshopper3D"
      },
      e18Towers: {
        name: "18 Towers",
        link: "18-towers",
        title: "An alternative to high denity mixed neighborhood design in Israel",
        subTitle:
          "using computational tools to derive an architectural language from local conditions - access to sun, light, and air at the apartment scale, leading to the building, block, and the neighborhood"
      },
      apartmentExplorations: {
        name: "Apartment Explorations",
        link: "apartment-explorations",
        title: "the basic unit - the apartment",
        subTitle:
          "Returning to first principles, exploring the geometrical conditions to maximaise sun light and wind exposur"
      },
      visualizingClimate: {
        name: "Visualizing Climate",
        link: "visualizing-climate"
      }
    }
  },
  computation: {
    name: "Computation",
    link: "computation",
    children: {
      cdp: {
        name: "Collaborative Design Platform",
        link: "collaborative-design-platform"
      },
      solarEnvelopes: {
        name: "Parametric Solar Envelopes",
        link: "parametric-solar-envelopes"
      },
      elytraPavilion: {
        name: "Elytra Filament Pavilion",
        link: "elytra-filament-pavilion"
      },
      lux: {
        name: "City Lux Tool",
        link: "city-lux-tool"
      },
      plotBot: {
        name: "Plot Bot",
        link: "plot-bot"
      }
    }
  },
  art: {
    name: "Art",
    link: "art",
    children: {
      carving: {
        name: "Wood Carving",
        link: "carving"
      }
    }
  },
  bio: {
    name: "Bio",
    link: "bio"
  },
  stories: {
    name: "stories",
    link: "stories",
    children: {
      aFrameHouse: {
        name: "A Frame House",
        link: "a-frame-house"
      },
      // drawingCrags: {
      //   name: "Drawing Crags",
      //   link: "drawing-crags"
      // },
      graphs: {
        name: "Visualizing Graphs",
        link: "visualizing-graphs"
      }
    }
  }
};

export default links;
