import React from "react";

import { Home, Linkedin, GitHub } from "react-feather";

import styles from "./footer.module.scss";

const Footer: React.FC = () => {
  return (
    <div className={styles.footer}>
      <div className={styles.copyContainer}>
        <div className={styles.copyright}>© 2022 by Boris Pl</div>
      </div>
      <div className={styles.links}>
        <div>
          <a href={"/"} rel="noreferrer">
            <Home />
          </a>
        </div>
        <div>
          <a
            href={"https://www.linkedin.com/in/boris-plotnikov-9481a012/"}
            target={"_blank"}
            rel="noreferrer"
          >
            <Linkedin />
          </a>
        </div>
        <div>
          <a href={"https://github.com/boris-p/"} target={"_blank"} rel="noreferrer">
            <GitHub />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
