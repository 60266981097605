import React from "react";
import { Assets } from "types";
import Img from "./Img";

import { useMediaQuery } from "react-responsive";
import cn from "classnames";

import styles from "./asset.module.scss";

export type AssetProps = {
  asset: Assets.Asset;
  widthOverride?: number;
  className?: string;
  desc?: string;
  style?: any;
};

const Asset: React.FC<AssetProps> = ({
  asset,
  children,
  widthOverride,
  desc = "",
  className = "",
  ...rest
}) => {
  const isSmallScreen = useMediaQuery({ query: "(max-width: 1224px)" });

  if (asset.assetType === "image") {
    const _asset = asset as Assets.Image;

    let width,
      height: any = 0;

    if (isSmallScreen) {
      width = "100%";
      height = "auto";
    } else {
      width = widthOverride || _asset.width;
      height =
        width === widthOverride ? _asset.height! * (widthOverride / asset.width) : _asset.height;
    }
    return (
      <>
        <Img
          {...rest}
          className={cn(className, styles.asset, { [styles.smallScreenImg]: isSmallScreen })}
          src={_asset.src}
          width={width as any}
          height={height as any}
        />
        {desc && (
          <div>
            <small>{desc}</small>
          </div>
        )}
      </>
    );
  }
  // todo - support other asset types
  return null;
};

export const AssetInRow: React.FC<AssetProps> = props => {
  return (
    <div className={styles.oneInRow}>
      <Asset {...props} />
    </div>
  );
};

export default Asset;
