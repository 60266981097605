import React, { Suspense, lazy } from "react";
import { Route, Switch } from "react-router-dom";

import Spinner from "components/spinner";
import links from "pages/app/links";
import StoriesRouterIndex from ".";

const AFrameHouse = lazy(() => import("pages/stories/aFrameHouse"));
// const DrawingCrags = lazy(() => import("pages/stories/drawingCrags"));
const Graphs = lazy(() => import("pages/stories/graphs"));

const StoriesRouter: React.FC = () => {
  const base = links.stories.link;

  const l = (link: string) => `/${base}/${link}`;

  return (
    <Suspense fallback={<Spinner />}>
      <Switch>
        <Route exact path={`/${base}`} component={StoriesRouterIndex} />
        <Route path={l(links.stories.children.aFrameHouse.link)} component={AFrameHouse} />
        <Route path={l(links.stories.children.graphs.link)} component={Graphs} />
      </Switch>
    </Suspense>
  );
};

export default StoriesRouter;
